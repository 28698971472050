import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl, ValidatorFn} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {BusinessLine, IdNameData, PlatformService, PriceHistory, Service, ServiceStatus, ServiceType} from '../../../@core/api/platform.service';

interface PriceLineFormConfig {
  billable: [boolean, ValidatorFn];
  description: string;
  pricex100: [number, ValidatorFn];
  discountx100: [number, ValidatorFn];
  start_date: [Date, ValidatorFn];
  end_date: Date | null;
}

/**
 * Component to be opened in order to edit/create a service for a specific customer, account and bot.
 * - customerId is mandatory.
 * - customerAccountsData contains the accounts data that you want to offer.
 * - accountId can be given to set an initial account id. It must be included in the customerAccountsData array.
 * - customeBotsData contains the bots data that you want to offer.
 * - botId can be given to set an initial bot id. It must be included in the customerBotsData array. It must be equal to service.bot_id if a service is given to edit.
 * - serviceTypes contains the service types that you want to offer.
 * - service can be given to set an initial service data to edit. Its type must be in the serviceTypes array.
 */
@Component({
  selector: 'ngx-edit-service-dialog',
  templateUrl: 'edit-service-dialog.component.html',
  styleUrls: ['edit-service-dialog.component.scss'],
})
export class EditServiceDialogComponent implements OnInit, OnDestroy {
  /** Customer Id owner of the account-bot-service */
  @Input() customerId: string;
  /** Optional Customer Name owner of the account-bot-service (for display purposes) */
  @Input() customerName: string;
  /** When creating a service, this should include all the accounts data for that customer.
   * To restrict editing or creating for a specific account, fill this only with data relative to the
   * account with the given accountId.
   * */
  @Input() customerAccountsData: IdNameData[];
  /** Account Id owner of the bot service. Can be undefined if this is a new service. */
  @Input() accountId?: string;
  /** When creating a service, this should include all the customer bots data for that customer.
   * To restrict editing or creating for a specific bot, fill this only with data relative to the
   * bot with the given botId.
   * */
  @Input() customerBotsData: IdNameData[];
  /** Account Id owner of the bot service. Can be undefined if this is a new service. If service is given, this must be the service.bot_id.*/
  @Input() botId?: string;
  /** Available service types - can be restricted if changing the service type is not desired. */
  @Input() serviceTypes: ServiceType[] = ['VOICE_ASSISTANT', 'PBX', 'MESSAGES', 'OTHER', 'WHATSAPP_INBOUND', 'WHATSAPP_OUTBOUND', 'SMS_OUTBOUND'];
  /** Service to edit - if not given, an empty service will be created. Its */
  @Input() service?: Service;

  constructor(protected dialogReference: NbDialogRef<EditServiceDialogComponent>, private formBuilder: UntypedFormBuilder, private toastrService: NbToastrService, private papi: PlatformService) {}

  serviceForm: UntypedFormGroup;
  loading: boolean = false;
  serviceStatuses: ServiceStatus[] = ['ENROLLED', 'CANCELLED', 'PAUSED', 'TRIAL', 'DRAFT'];
  businessLines: BusinessLine[] = ['RESTAURANTS', 'HOTELS', 'B2B', 'HEALTHCARE', 'LOGISTICS'];
  edit_mode: boolean = false;

  sanityInputChecks(): {result: boolean; message: string} {
    if (this.service && (!this.botId || this.service.bot_id != this.botId)) {
      const message = 'input botId and service bot_id do not match';
      console.error(message);
      return {result: false, message: message};
    }
    if (this.botId && this.customerBotsData.find((bot_data) => bot_data.id == this.botId) === undefined) {
      const message = 'input botId not present in customerBotsData';
      console.error(message);
      return {result: false, message: message};
    }
    if (this.serviceTypes.length < 1) {
      const message = 'serviceTypes must include at least one service type';
      console.error(message);
      return {result: false, message: message};
    }
    if (this.customerBotsData.length < 1) {
      const message = 'no available bots';
      console.error(message);
      return {result: false, message: message};
    }
    if (this.customerAccountsData.length < 1) {
      const message = 'no available accounts';
      console.error(message);
      return {result: false, message: message};
    }
    if (this.service && !this.serviceTypes.includes(this.service.service_type)) {
      const message = 'input service.service_type not included in serviceTypes';
      console.error(message);
      return {result: false, message: message};
    }
    if (this.accountId && this.customerAccountsData.find((account_data) => account_data.id == this.accountId) === undefined) {
      const message = 'input accountId not included in customerAccountsData';
      console.error(message);
      return {result: false, message: message};
    }
    return {result: true, message: 'ok'};
  }

  ngOnInit() {
    const sanity_check_result = this.sanityInputChecks();
    if (!sanity_check_result.result) {
      this.dialogReference.close({service: null, toastr: {message: 'Error', title: 'Invalid input for service editing. (' + sanity_check_result.message + ')', userConfig: {status: 'danger'}}});
    }
    const serviceFormConfig = {
      customer_id: [this.customerId, Validators.required],
      account_id: [this.accountId, Validators.required],
      bot_id: [this.botId, Validators.required],
      service: this.formBuilder.group({
        business_line: ['RESTAURANTS', Validators.required],
        status: 'ENROLLED',
        service_type: this.serviceTypes.includes('VOICE_ASSISTANT') ? 'VOICE_ASSISTANT' : this.serviceTypes[0],
        price_history: this.formBuilder.array([], Validators.required),
      }),
    };
    if (typeof this.service !== 'undefined') {
      this.edit_mode = true;
      serviceFormConfig.service = this.formBuilder.group({
        business_line: [this.service.business_line, Validators.required],
        status: this.service.status,
        service_type: this.service.service_type,
        price_history: this.formBuilder.array(
          this.service?.price_history?.map((price_history) => {
            return this.formBuilder.group({
              billable: price_history.billable,
              description: price_history.description,
              pricex100: parseFloat((price_history.price_100 / 100).toFixed(2)),
              discountx100: parseFloat((price_history.discount_100 / 100).toFixed(2)),
              start_date: new Date(price_history.start_date),
              end_date: price_history.end_date ? new Date(price_history.end_date) : null,
            });
          }),
          Validators.required
        ),
      });
    }
    this.serviceForm = this.formBuilder.group(serviceFormConfig);
  }

  setParentAccount() {
    const new_account = this.customerAccountsData.find((account) => account.id == this.selectedAccountId);
    if (new_account === undefined) {
      console.log('Account ' + this.selectedAccountId + ' cannot be set as it is not present in the given customer accounts.');
      this.selectedAccountId = this.accountId;
      return;
    }
    this.accountId = this.selectedAccountId;
  }

  set selectedAccountId(account_id: string) {
    if (this.customerAccountsData.find((account) => account.id == account_id) === undefined) {
      console.error('Account ' + account_id + ' cannot be set as it is not present in the given customer accounts.');
      return;
    }
    this.serviceForm.get('account_id').setValue(account_id);
  }

  get selectedAccountId(): string {
    return this.serviceForm.get('account_id').value;
  }

  get priceHistoryFormArray(): UntypedFormArray {
    return (this.serviceForm.get('service') as UntypedFormGroup).get('price_history') as UntypedFormArray;
  }

  get botName(): string {
    const botData = this.customerBotsData.find((bot_data) => bot_data.id == this.botId);
    return botData ? botData.name : '';
  }

  addFirstPriceLine(): void {
    const newPriceLine: PriceLineFormConfig = {
      billable: [true, Validators.required],
      description: null,
      pricex100: [149.0, Validators.required],
      discountx100: [0.0, Validators.required],
      start_date: [null, Validators.required],
      end_date: null,
    };
    if (this.priceHistoryFormArray.length > 0 && false) {
      const start_date_next = this.priceHistoryFormArray.at(0).get('start_date').value as Date;
      newPriceLine.end_date = new Date(start_date_next.setDate(start_date_next.getDate() - 1));
      newPriceLine.start_date = [new Date(start_date_next.setDate(start_date_next.getDate() - 2)), Validators.required];
    }
    this.priceHistoryFormArray.insert(0, this.formBuilder.group(newPriceLine));
  }

  appendPriceLine(price_line_index: number): void {
    const newPriceLine: PriceLineFormConfig = {
      billable: [true, Validators.required],
      description: null,
      pricex100: [149.0, Validators.required],
      discountx100: [0.0, Validators.required],
      start_date: [null, Validators.required],
      end_date: null,
    };
    this.priceHistoryFormArray.insert(price_line_index + 1, this.formBuilder.group(newPriceLine));
  }

  removePriceLine(price_line_index: number): void {
    this.priceHistoryFormArray.removeAt(price_line_index);
  }

  updateBotId() {
    const new_bot = this.customerBotsData.find((bot_data) => bot_data.id === this.selectedBotId);
    if (new_bot === undefined) {
      console.log('Bot ' + this.selectedBotId + ' cannot be set as it is not present in the given customer bots.');
      this.selectedBotId = this.botId;
      return;
    }
    this.botId = this.selectedBotId;
  }

  set selectedBotId(bot_id: string) {
    if (this.customerBotsData.find((bot) => bot.id == bot_id) === undefined) {
      console.error('Bot ' + bot_id + ' cannot be set as it is not present in the given customer bots.');
      return;
    }
    this.serviceForm.get('bot_id').setValue(bot_id);
  }

  get selectedBotId(): string {
    return this.serviceForm.get('bot_id').value;
  }

  get priceHistoryFromForm(): PriceHistory[] {
    const priceHistoryData = this.serviceForm.value.service?.price_history;
    const newPriceHistory: PriceHistory[] = [];
    for (const formPriceHistory of priceHistoryData) {
      newPriceHistory.push({
        billable: formPriceHistory.billable,
        description: formPriceHistory.description,
        start_date: formPriceHistory.start_date.toISOString(),
        end_date: formPriceHistory.end_date ? formPriceHistory.end_date.toISOString() : null,
        price_100: Math.round(formPriceHistory.pricex100 * 100),
        discount_100: Math.round(formPriceHistory.discountx100 * 100),
      });
    }
    return newPriceHistory;
  }

  get serviceFromForm(): Service {
    const serviceFormData = this.serviceForm.value.service;
    const new_service: Service = {
      service_type: serviceFormData?.service_type,
      bot_id: this.serviceForm.value.bot_id,
      status: serviceFormData?.status,
      business_line: serviceFormData?.business_line,
      price_history: this.priceHistoryFromForm,
    };
    return new_service;
  }

  collectErrors(control: AbstractControl): any | null {
    let errors = {};
    let recursiveFunc = (key: string, control: AbstractControl) => {
      if (control instanceof UntypedFormGroup) {
        Object.entries(control.controls).forEach(([key, childControl]) => recursiveFunc(key, childControl));
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((childControl, index) => recursiveFunc('[' + index.toString() + ']', childControl));
      } else {
        errors[key] = control.errors;
      }
    };
    recursiveFunc('root', control);
    return errors;
  }

  async save() {
    if (!this.serviceForm.valid) {
      console.log(this.collectErrors(this.serviceForm));
      this.toastrService.show('Error', 'Form is invalid', {status: 'danger'});
      return false;
    }
    const updated_service = this.serviceFromForm;
    this.dialogReference.close({
      service: updated_service,
      account_id: this.selectedAccountId,
      toastr: {message: 'Success', title: this.edit_mode ? 'Service Updated Successfully' : 'Service Created Successfully', userConfig: {status: 'success'}},
    });
  }

  dismiss() {
    this.dialogReference.close({service: null, toastr: {message: 'Info', title: this.edit_mode ? 'Service Not Updated' : 'Service Not Created', userConfig: {status: 'info'}}});
  }

  ngOnDestroy() {}
}
