import {ClientActivity} from '../data/client-activity';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {Amend} from './platform.service';
import {InvoiceData} from './billing.service';

@Injectable({
  providedIn: 'any',
})
export class WebFunService {
  constructor(private http: HttpClient) {}

  public addToken(token): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/push-notifications/token', {token: token});
  }
  public sendMeetingNotification(payload): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/meeting-notifications', payload);
  }
  public getDeletedBots(): Observable<any> {
    return this.http.get(environment.api.webfunToken + '/deleted-bots');
  }

  public getSlugData(slug): Observable<any> {
    return this.http.get(environment.api.webfunToken + '/slug/' + slug);
  }
  public getAllHotelAddons(): Observable<any> {
    return this.http.get(environment.api.webfunToken + '/hotel-addons');
  }
  public getAllHotelIBE(): Observable<any> {
    return this.http.get(environment.api.webfunToken + '/hotel-ibe');
  }
  public addAddon(addon): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/hotel-addons', addon);
  }
  public addIBE(ibe): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/hotel-ibe', ibe);
  }
  public putAddon(addon): Observable<any> {
    return this.http.put(environment.api.webfunToken + '/hotel-addons/' + addon.id, addon);
  }
  public putIBE(ibe): Observable<any> {
    return this.http.put(environment.api.webfunToken + '/hotel-ibe/' + ibe.id, ibe);
  }
  public patchAddon(addon): Observable<any> {
    return this.http.patch(environment.api.webfunToken + '/hotel-addons/' + addon.id, addon);
  }
  public deleteAddon(id): Observable<any> {
    return this.http.delete(environment.api.webfunToken + '/hotel-addons/' + id);
  }
  public deleteIBE(id): Observable<any> {
    return this.http.delete(environment.api.webfunToken + '/hotel-ibe/' + id);
  }
  public deleteNotifications(scheduler: string, run: string): Observable<any> {
    return this.http.delete(environment.api.webfunToken + '/notifications-scheduler-run/' + scheduler + '/' + run);
  }
  public transcript(data: any): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/stt', data);
  }
  public getTtsTest(data: any): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/tts/test', data, {responseType: 'blob'});
  }
  public getTtsUrl(data: any): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/tts', data);
  }
  public testMessage(payload: any): Observable<any> {
    return this.http.post(environment.api.webfunToken + '/jambonz-call', payload);
  }
  public getZones(slug: string): Observable<any> {
    return this.http.get(environment.api.webfunToken + '/booking-manager/cover/' + slug + '/zones');
  }
  public checkTheFork(restaurantId: string): Observable<any> {
    return this.http.post<any>(environment.api.webfunToken + '/thefork', {restaurantId}, {observe: 'response'}).pipe(catchError((error) => throwError(error)));
  }

  public postAmend(amend: Amend): Observable<any> {
    return this.http.post<any>(environment.api.webfunToken + '/amends', {amend}, {observe: 'response'}).pipe(catchError((error) => throwError(error)));
  }
  public updateAmends(amendsToUpdate: any): Observable<any> {
    return this.http.put<any>(environment.api.webfunToken + '/amends', {amendsToUpdate}, {observe: 'response'}).pipe(catchError((error) => throwError(error)));
  }
  public getAllAmendsByAccountId(account_id: string): Observable<any> {
    return this.http.get<any>(environment.api.webfunToken + `/amends/${account_id}`, {observe: 'response'}).pipe(catchError((error) => throwError(error)));
  }
  public saveInvoiceBatch(invoices: InvoiceData[]): Observable<any> {
    return this.http
      .post<any>(
        `${environment.api.webfunToken}/billing/save-batch`,
        {invoices}, //
        {observe: 'response'}
      )
      .pipe(catchError((error) => throwError(error)));
  }

  public sendInvoicesToHolded(batchId: string): Observable<any> {
    return this.http.post<any>(`${environment.api.webfunToken}/billing/send-invoices-to-holded/${batchId}`, {}, {observe: 'response'}).pipe(catchError((error) => throwError(() => error)));
  }
  public chargeInvoices(batchId: string): Observable<any> {
    return this.http.post<any>(`${environment.api.webfunToken}/billing/batch/${batchId}/charge-gocardless`, {}, {observe: 'response'}).pipe(catchError((error) => throwError(() => error)));
  }
}
