import {inject, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Auth, onAuthStateChanged, signOut} from '@angular/fire/auth';
import {PlatformService} from '../api/platform.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private auth: Auth = inject(Auth);

  constructor(private ps: PlatformService) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(this.auth, async (user) => {
        if (user) {
          const email = user?.email || '';
          if (email.endsWith('@bookline.ai') || email.endsWith('@bookline.io')) {
            // console.log('Dominio autorizado, verificando rol de usuario');
            const userInBD = await this.ps.getUser(user.uid).toPromise();
            if (userInBD?.role === 'ADMIN') {
              // console.log('Acceso autorizado para usuarios con rol ADMIN');

              resolve(true);
            } else {
              console.log('Acceso denegado. Usuario no es ADMIN, cerrando sesión');
              signOut(this.auth);
              resolve(false);
            }
          } else {
            console.log('Acceso denegado. Dominio no autorizado.');
            signOut(this.auth);
            resolve(false);
          }
        } else {
          // console.log('Usuario no autenticado');
          resolve(true);
        }
      });
    });
  }
}
