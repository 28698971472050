<nb-card class="amend-modal-wrapper">
  <nb-card-header>Edit Amend</nb-card-header>
  <nb-card-body [nbSpinner]="loading">
    <form [formGroup]="amendForm">
      <!-- Service ID -->
      <div class="form-group row">
        <label class="label col-sm-2 col-form-label">Service ID</label>
        <div class="col-sm-10">
          <nb-select fullWidth placeholder="Select Service by Bot ID" formControlName="service_id" (selectedChange)="onServiceChange($event)">
            <nb-option *ngFor="let service of services" [value]="service.bot_id"> {{ service.bot_id }}</nb-option>
          </nb-select>
        </div>
      </div>

      <!-- Billing Start Date -->
      <div class="form-group row col-sm-12">
        <label class="label col-sm-2 col-form-label" style="padding: 0px">Billing Start Date</label>
        <div class="col-sm-4">
          <input type="text" [nbDatepicker]="startDatePicker" nbInput formControlName="billing_start_date" placeholder="Select Start Date" />
          <nb-datepicker #startDatePicker [format]="'dd/MM/yyyy'"></nb-datepicker>
        </div>
        <!-- Billing End Date -->

        <label class="label col-sm-2 col-form-label">Billing End Date</label>
        <div class="col-sm-4">
          <input type="text" [nbDatepicker]="endDatePicker" nbInput formControlName="billing_end_date" placeholder="Select End Date" />
          <nb-datepicker #endDatePicker [format]="'dd/MM/yyyy'"></nb-datepicker>
        </div>
      </div>

      <!-- Not Billed Reason
      <div class="form-group row">
        <label class="label col-sm-2 col-form-label">Not Billed Reason</label>
        <div class="col-sm-10">
          <textarea fullWidth nbInput formControlName="not_billed_reason"></textarea>
        </div>
      </div> -->

      <div class="form-group row" formGroupName="discount">
        <label class="label col-sm-2 col-form-label">Discount</label>
        <div class="col-sm-10">
          <nb-list>
            <nb-list-item>
              <div class="form-group row">
                <!-- Motive -->
                <div class="col-12" style="padding: 0px; display: flex; gap: 24px">
                  <!-- Scope -->
                  <label class="label col-form-label">Scope</label>
                  <nb-select fullWidth formControlName="discount_scope">
                    <nb-option *ngFor="let scope of discountScopes" [value]="scope">{{ scope }}</nb-option>
                  </nb-select>
                  <!-- Mode -->
                  <label class="label col-form-label">Mode</label>
                  <nb-select fullWidth formControlName="discount_mode" (selectedChange)="onDiscountModeChange($event)">
                    <nb-option *ngFor="let mode of discountModes" [value]="mode">{{ mode }}</nb-option>
                  </nb-select>
                  <!-- Motive -->
                  <label class="label col-form-label">Motive</label>
                  <nb-select fullWidth formControlName="discount_type">
                    <nb-option *ngFor="let type of discountTypes" [value]="type">{{ type }}</nb-option>
                  </nb-select>
                </div>

                <!-- Discount -->
                <div class="col-12" style="padding: 0px; display: flex; gap: 24px; margin-top: 24px">
                  <label class="label col-form-label">Service Price</label>
                  <nb-form-field>
                    <input type="number" nbInput readonly [value]="currentServicePrice" />
                    <span nbSuffix>€</span>
                  </nb-form-field>
                  <label class="label col-form-label">Discount</label>
                  <nb-form-field>
                    <input type="number" nbInput formControlName="discount_value" (input)="onDiscountChange($event.target.value)" />
                    <span nbSuffix>
                      {{ amendForm.get('discount.discount_mode')?.value === 'RELATIVE' ? '%' : '€' }}
                    </span>
                  </nb-form-field>
                  <!-- Final price -->
                  <label class="label col-form-label">Final Price</label>
                  <nb-form-field>
                    <input type="number" nbInput readonly [value]="finalPrice" />
                    <span nbSuffix>€</span>
                  </nb-form-field>
                </div>

                <!-- Reason -->
                <div class="col-12" style="padding: 0px; margin-top: 24px">
                  <label class="label col-form-label">Reason</label>
                  <div style="display: flex">
                    <textarea fullWidth nbInput formControlName="discount_reason"></textarea>
                  </div>
                </div>
              </div>
            </nb-list-item>
          </nb-list>
        </div>
      </div>

      <!-- Service Calculations -->
      <!-- <div class="form-group row" formGroupName="service_calculations">
        <label class="label col-sm-2 col-form-label">Expected Amount</label>
        <div class="col-sm-10">
          <input type="number" fullWidth nbInput formControlName="expected_amount" />
        </div>
        <label class="label col-sm-2 col-form-label">Billed Amount</label>
        <div class="col-sm-10">
          <input type="number" fullWidth nbInput formControlName="billed_amount" />
        </div>
      </div> -->
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" (click)="save()">Save</button>
    <button nbButton ghost status="primary" (click)="dismiss()">Cancel</button>
  </nb-card-footer>
</nb-card>
