import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {LanguageSelectorDialogComponent} from './laguage-selector/language-selector-dialog.component';

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms
@Component({
  selector: 'ngx-json-input',
  styleUrls: ['./json-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  template: `
    <ng-container [formGroup]="form">
      <div style="width: 100%; align-items: center; display: flex">
        <nb-form-field style="width: calc(100% - 55px); display: inline-flex; margin-right: 5px">
          <nb-icon nbPrefix icon="file-code" pack="fas"></nb-icon>
          <input type="text" nbInput fullWidth [formControlName]="controlName" placeholder="" type="hidden" />
          <input type="text" nbInput fullWidth [value]="selectedLanguageText" [readonly]="true" [placeholder]="placeholderText" />
        </nb-form-field>
        <button nbButton status="primary" style="width: 55px; display: inline-flex" (click)="openJSON()">
          <nb-icon icon="pen" pack="fas"></nb-icon>
        </button>
      </div>
    </ng-container>
  `,
})
export class JSONInputComponent implements OnInit {
  // @ViewChild('input', { static: true }) input: ElementRef;
  // @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() controlName: string;
  @Input() languages?: string[];
  @Input() selectedLanguageCode: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() popUpTitle?: string;
  @Input() placeholderJson?: string;
  @Input() skip_validation?: boolean = false;

  form: UntypedFormGroup;
  constructor(private dialogService: NbDialogService, public controlContainer: ControlContainer) {}

  ngOnInit() {
    if (this.formGroup != null) {
      this.form = this.formGroup;
    } else {
      this.form = <UntypedFormGroup>this.controlContainer.control;
    }
  }

  openJSON() {
    this.dialogService
      .open(LanguageSelectorDialogComponent, {
        context: {
          title: 'Edit JSON - ' + (this.popUpTitle != null ? this.popUpTitle : this.controlName),
          supported_languages: this.languages,
          value: this.form.get(this.controlName).value,
          placeholdersData: this.placeholderJson,
          skip_validation: this.skip_validation,
        },
      })
      .onClose.subscribe((value) => {
        if (value != null) {
          this.form.get(this.controlName).setValue(value);
        }
      });
  }

  get placeholderText(): string {
    if (this.placeholderJson != null) {
      const json_data = JSON.parse(this.placeholderJson);
      try {
        const lang_code: string = this.selectedLanguageCode;
        if (lang_code in json_data) {
          return json_data[lang_code];
        }
      } catch (ex) {
        console.log('Error in calling placeholderText');
        console.log(ex);
      }
    }
    return '';
  }

  get selectedLanguageText() {
    if (!this.selectedLanguageCode) {
      return this.form.get(this.controlName).value;
    }
    if (this.form.get(this.controlName).value) {
      const json_data = JSON.parse(this.form.get(this.controlName).value);
      try {
        const lang_code: string = this.selectedLanguageCode;
        if (lang_code in json_data) {
          return json_data[lang_code];
        } else if ((lang_code == 'es-MX' || lang_code == 'es-PY' || lang_code == 'es-CL' || lang_code == 'es-UY' || lang_code == 'es-CO') && 'es-ES' in json_data) {
          // If mexican, uruguayan, or colombian is selected but is not present
          // and spanish is present, copy from spanish
          json_data[lang_code] = json_data['es-ES'];
          this.form.get(this.controlName).setValue(JSON.stringify(json_data));
          return json_data[lang_code];
        } else if (lang_code == 'en-GB' && 'en-US' in json_data) {
          // If british is selected but is not present
          // and english is present, copy from english
          json_data[lang_code] = json_data['en-US'];
          this.form.get(this.controlName).setValue(JSON.stringify(json_data));
          return json_data[lang_code];
        }
      } catch (ex) {
        console.log('Error in calling selectedLanguageCode');
        console.log(ex);
      }
    }
    return this.form.get(this.controlName).value;
  }
}
