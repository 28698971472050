import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl, ValidatorFn, FormArray, FormGroup} from '@angular/forms';
import {v4 as uuidv4} from 'uuid';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {Amend, Discount, ServiceCalculations, AmendDiscountType, AmendDiscountScope, AmendDiscountMode} from '../../../@core/api/platform.service';
import {WebFunService} from '../../../@core/api/webfun.service';

/**
 * Component to be opened in order to create or edit an Amend for a specific account.
 * - `accountId` is mandatory and provided by the parent component, ensuring the Amend is tied to the correct account.
 * - `amend` can be passed to edit an existing Amend; if not provided, a new one will be created.
 * - `services` is an optional array of services available to the account, used to populate the Service ID dropdown.
 * - `status`, `billingDate`, `holdedInvoiceId`, `notBilledReason`, and `discounts` are optional inputs to prepopulate the form when editing an Amend.
 * - The component handles form validation, including required fields and minimum values for discounts and service calculations.
 * - On successful save, the form data is mapped to an Amend object and returned to the parent component.
 * - `edit_mode` is determined based on whether an Amend is passed, enabling the component to differentiate between creation and editing flows.
 */
@Component({
  selector: 'ngx-edit-amend-dialog',
  templateUrl: 'edit-amend-dialog.component.html',
  styleUrls: ['edit-amend-dialog.component.scss'],
})
export class EditAmendDialogComponent implements OnInit, OnDestroy {
  /**
   * The status of the Amend, indicating its current lifecycle stage (e.g., ACTIVE, PAUSED, CANCELLED).
   * This value is optional and used to prepopulate the form when editing an existing Amend.
   */
  @Input() status?: string;

  /**
   * The ID of the account associated with the Amend. This value is mandatory and provided by the parent component.
   * It ensures the Amend is tied to the correct account.
   */
  @Input() accountId?: string;

  /**
   * The billing date of the Amend, used to track when the Amend should be billed.
   * This value is optional and used to prepopulate the form when editing.
   */
  @Input() billingDate?: string;

  /**
   * The ID of the invoice in Holded (external billing system), if the Amend is already synchronized with it.
   * This value is optional and relevant for existing Amend records.
   */
  @Input() holdedInvoiceId?: string;

  /**
   * The reason why the Amend is not billed, used to provide additional context for skipped billing.
   * This value is optional and prepopulated when editing an Amend.
   */
  @Input() notBilledReason?: string;

  /**
   * An array of discounts applied to the Amend. Each discount includes details like type, value, and reason.
   * This value is optional and prepopulates the discounts section of the form when editing.
   */
  @Input() discount?: Discount;

  /**
   * The calculations related to services associated with the Amend, tracking expected vs. billed amounts.
   * This value is optional and used to prepopulate the service calculations section when editing.
   */
  @Input() serviceCalculations?: ServiceCalculations;

  /**
   * The start date of the billing period. This value is optional and provided when editing an existing Amend.
   * Represents the beginning of the period for which the Amend applies.
   */
  @Input() billingStartDate?: string;

  /**
   * The end date of the billing period. This value is optional and provided when editing an existing Amend.
   * Represents the end of the period for which the Amend applies.
   */
  @Input() billingEndDate?: string;
  /**
   * A list of services available for the account, used to populate the dropdown for selecting a service ID.
   * This value is optional and provided by the parent component to support dynamic selection.
   */

  @Input() services?: any;

  /**
   * The Amend object being edited, if applicable. If provided, the form will be prepopulated with its values.
   * If not provided, the component operates in creation mode, initializing an empty form.
   */
  @Input() amend?: Amend;

  amendForm: UntypedFormGroup;
  loading: boolean = false;
  edit_mode: boolean = false;
  currentServicePrice = 0;
  finalPrice = 0;

  constructor(protected dialogReference: NbDialogRef<EditAmendDialogComponent>, private formBuilder: UntypedFormBuilder, private toastrService: NbToastrService, private wf: WebFunService) {}

  ngOnInit() {
    // Configuración inicial del formulario
    const ammendFormConfig = {
      account_id: [this.accountId], // Siempre proporcionado por el padre
      service_id: [''], // Seleccionado por el usuario
      bot_id: [this.amend?.bot_id || ''],
      billing_start_date: this.amend?.billing_start_date ? [new Date(this.amend?.billing_start_date || '')] : '',
      billing_end_date: this.amend?.billing_end_date ? [new Date(this.amend?.billing_end_date || '')] : '',
      holded_invoice_id: [this.amend ? this.amend.holded_invoice_id : ''],
      not_billed_reason: [this.amend ? this.amend.not_billed_reason : ''],
      discount: this.formBuilder.group({
        discount_type: [this.amend?.discount?.discount_type || 'ADJUSTMENT'],
        discount_scope: [this.amend?.discount?.discount_scope || 'PERIOD'],
        discount_mode: [this.amend?.discount?.discount_mode || 'ABSOLUTE'],
        discount_value: [this.amend?.discount?.discount_value || 0],
        discount_reason: [this.amend?.discount?.discount_reason || ''],
      }),

      service_calculations: this.formBuilder.group({
        service_id: [this.amend?.service_calculations?.service_id || ''],
        expected_amount: [this.amend?.service_calculations?.expected_amount || null],
        billed_amount: [this.amend?.service_calculations?.billed_amount || null],
      }),
    };
    this.amendForm = this.formBuilder.group(ammendFormConfig);

    this.edit_mode = !!this.amend;

    if (this.services && this.services.length > 0) {
      this.amendForm.get('service_id')?.setValue(this.services[0].bot_id);
      this.onServiceChange(this.services[0].bot_id);
    }
  }

  onServiceChange(serviceId: string) {
    // Buscar el servicio seleccionado
    const selectedService = this.services?.find((service) => service.bot_id === serviceId);
    if (selectedService) {
      this.amendForm.get('bot_id')?.setValue(selectedService.bot_id);
      this.amendForm.get('service_calculations.service_id')?.setValue(selectedService.bot_id);
      const price = (selectedService.price_history[0]?.price_100 || 0) / 100;
      this.currentServicePrice = price;

      // Recalcular el precio final
      const discountValue = this.amendForm.get('discount.discount_value')?.value || 0;
      const discountMode = this.amendForm.get('discount.discount_mode')?.value;
      this.calculateFinalPrice(price, discountValue, discountMode);
    }
  }

  calculateFinalPrice(servicePrice: number, discountValue: number, discountMode: 'ABSOLUTE' | 'RELATIVE') {
    if (discountMode === 'RELATIVE') {
      // Calcular descuento relativo
      const discountAmount = (servicePrice * discountValue) / 100;
      this.finalPrice = Math.max(servicePrice - discountAmount, 0);
    } else if (discountMode === 'ABSOLUTE') {
      // Calcular descuento absoluto
      this.finalPrice = Math.max(servicePrice - discountValue, 0);
    }
  }

  onDiscountChange(discountValue: number) {
    const discountMode = this.amendForm.get('discount.discount_mode')?.value;
    this.calculateFinalPrice(this.currentServicePrice, discountValue, discountMode);
  }

  onDiscountModeChange(mode: 'ABSOLUTE' | 'RELATIVE') {
    const discountValue = this.amendForm.get('discount.discount_value')?.value || 0;
    this.calculateFinalPrice(this.currentServicePrice, discountValue, mode);
  }

  get discountsFormArray(): FormArray {
    return this.amendForm.get('discounts') as FormArray;
  }

  get discountTypes(): AmendDiscountType[] {
    return ['INCIDENT', 'ADJUSTMENT', 'TEMPORARY_CLOUSURE', 'COMERCIAL_DISCOUNT', 'NON_PAYMENT', 'OTHER'];
  }
  get discountScopes(): AmendDiscountScope[] {
    return ['PERIOD', 'PRORATED'];
  }
  get discountModes(): AmendDiscountMode[] {
    return ['ABSOLUTE', 'RELATIVE'];
  }
  getFormValidationErrors(form: FormGroup): Record<string, any> {
    const errors: Record<string, any> = {};
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (control instanceof FormGroup) {
        errors[key] = this.getFormValidationErrors(control);
      } else if (control instanceof FormArray) {
        errors[key] = control.controls.map((arrayControl) => this.getFormValidationErrors(arrayControl as FormGroup));
      } else if (control && control.errors) {
        errors[key] = control.errors;
      }
    });
    return errors;
  }

  get amendFromForm(): Amend {
    const amendFormData = this.amendForm.value;

    const new_amend: Amend = {
      account_id: amendFormData.account_id,
      service_id: amendFormData.service_id,
      bot_id: amendFormData.bot_id,
      billing_start_date: amendFormData.billing_start_date,
      billing_end_date: amendFormData.billing_end_date,
      holded_invoice_id: amendFormData.holded_invoice_id ?? '',
      not_billed_reason: amendFormData.not_billed_reason,
      discount: amendFormData.discount
        ? {
            discount_type: amendFormData.discount.discount_type,
            discount_scope: amendFormData.discount.discount_scope,
            discount_mode: amendFormData.discount.discount_mode,
            discount_value: amendFormData.discount.discount_value,
            discount_reason: amendFormData.discount.discount_reason,
          }
        : {
            discount_type: 'ADJUSTMENT',
            discount_scope: 'PERIOD',
            discount_mode: 'ABSOLUTE',
            discount_value: 0,
            discount_reason: '',
          },

      service_calculations: {
        service_id: amendFormData.service_calculations?.service_id,
        expected_amount: amendFormData.service_calculations?.expected_amount,
        billed_amount: amendFormData.service_calculations?.billed_amount,
      },
    };

    return new_amend;
  }

  save() {
    if (!this.amendForm.valid) {
      this.toastrService.show('Error', 'Form is invalid', {status: 'danger'});
      return;
    }
    const updated_amend = this.amendFromForm;
    this.dialogReference.close({
      amend: updated_amend,
      toastr: {
        message: 'Success',
        title: this.edit_mode ? 'Amend Updated Successfully' : 'Amend Created Successfully',
        userConfig: {status: 'success'},
      },
    });
  }

  dismiss() {
    this.dialogReference.close({amend: null});
  }

  ngOnDestroy() {}
}
